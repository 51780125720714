import React from "react"
import { graphql } from "gatsby";
import { BlogProps, BlogListProps, BlogNode } from "../components/blog/blog-types";
import BlogList from "../components/blog/blog-list";
import SEO from "../components/seo";
import Layout from "../components/layout";

// TODO get rid of blopg type dependency
interface MarkdownPageProps {
    pageContext: any,
    data: {
        markdownRemark: BlogNode
    }
}

const MarkdownPage = ({ data, pageContext, ...props }: MarkdownPageProps) => {
    const title = data.markdownRemark.frontmatter.title
    return (
        <>
            <Layout>
                <SEO title={title} />
                <div className="static-page">
                    <section
                        dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
                        itemProp="articleBody"
                        />
                </div>
            </Layout>

        </>
    )
}

export default MarkdownPage


/**
 * Call like {tag: "/<name>/"}
 */
export const mdPageQuery = graphql`
    query SinglePageQuery(
        $filePath: String!,
    ) {
        markdownRemark(fileAbsolutePath: { eq: $filePath }) {
            html
            fields {
                slug
            }
            frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                path
            } 
        }
    }`
